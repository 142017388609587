//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import FormsCard from '@/modules/auth/components/forms-card.vue';

export default {
  name: 'forms-page',

  components: {
    [FormsCard.name]: FormsCard,
  },

  data() {
    return {
      imgLoading: true,
    }
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      is_labtop: 'layout/is_labtop',
    }),
  },
  methods: {
    onloadIamge() {
      console.log('loaded');
      this.imgLoading = false
    },
  },
};
