//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import i18n from '@/vueI18n';
// import Message from '@/shared/message/message';
// import firebase from 'firebase';
// import 'firebase/firestore'

export default {
  name: 'forms-card',

  data() {
    return {
      model: {
        name: null,
        phoneNumber: null,
        email: null,
        city: null,
        distributorType: null,
        status: 'pending',
      },
      options: [
        {
          label: this.i18n('entities.seller.fields.wholesaler'),
          value: 'wholesaler'
        },
        {
          label: this.i18n('entities.seller.fields.retailer'),
          value: 'retailer'
        },
      ],
      // loading: false,
      // language: getLanguageCode(),
    }
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      is_labtop: 'layout/is_labtop',
      loading: 'auth/submitLoading',
    }),
    language(){
      return i18n.locale
    }
  },
  methods: {
    ...mapActions({
      signInAnon: 'auth/signInAnon',
      doSubmitRequestForm: 'auth/doSubmitRequestForm',
    }),
   i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      // this.model = formSchema.initialValues(
      //   this.currentUser,
      // );
      // debugger
      this.model = {
        name: null,
        phoneNumber: null,
        email: null,
        city: null,
        distributorType: null,
      }
      // to reset validations:
      this.$refs.form.resetValidation()
    },
    pushNotify() {
      // const dismiss = 
      this.$q.notify({
        message: this.i18n('common.submitRequestSuccess'),
        timeout: 6000,
        color: 'white',
        textColor: 'green',
        position: 'center',
        icon: 'fas fa-check-circle',
      })
    },
    async doSubmit() {
      if (!this.model.distributorType) {
        return
      }
      this.model.status='pending'
      await this.doSubmitRequestForm(this.model)
      // if (res) {
        // await firebase.auth().currentUser.delete().then(function() {
        //   console.log('User deleted');
        // }).catch(function(error) {
        //   console.log('An error happened', error);
        // });
        // this.pushNotify()
        this.doReset()
      // }
    }
  },
};
